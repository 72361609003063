/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import styled from 'styled-components';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import theme from '../../styles/theme';


const CustomFormWrapper = styled.div`
  h2 {
    margin: 0 0 24px 0;
  }

  .field-item {
    margin: 0 0 16px 0;
  }

  input[type="text"],
  input[type="email"] {
    border-radius: 6px;
    border: 1px solid ${theme.borderColor.silver};
    color: ${theme.textColor.suvaGray};
    outline: none;
    padding: 2px 20px;
    width: 100%;
  }
  input[type="checkbox"] {
    margin-right: 7px;
    margin-top: 0;
    vertical-align: middle;
  }
  label {
    font-size: 16px;
    line-height: 1;
    vertical-align: middle;
  }

  .button-wrapper {
    button {
      background: ${theme.background.blue};
      border-radius: 6px;
      border: none;
      color: ${theme.textColor.white};
      cursor: pointer;
      display: inline-block;
      line-height: 22px;
      outline: none;
      padding: 10px 24px;
      text-decoration: none;
      transition: background ease-in-out 0.2s;
      white-space: nowrap;
      width: 100%;

      &:hover {
        background: ${theme.hover.hoverLight};
      }
    }
  }
`;
CustomFormWrapper.displayName = 'CustomFormWrapper';


/*------------------------------------------------*/
/* EXPORTS
/*------------------------------------------------*/
export default CustomFormWrapper;
