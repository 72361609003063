/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import {
  graphql,
} from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import _get from 'lodash/get';
import axios from 'axios';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import ContentfulRichText from '../components/ContentfulRichText';
import Layout from '../components/layout';
import Container from '../components/Container';
import { CTAButton } from '../styles/GlobalStyles';
import {
  BodyTextBig,
  ContentWrap,
  GlobalHero,
  GraySection,
  HeroHeading,
  SectionTitle,
  BodyText,
} from '../components/Global';
import SEO from '../components/seo';
import MediaBreakDown from '../styles/MediaBreakDown';
import theme from '../styles/theme';
import CustomForm from '../components/CustomForm';
import footerTopDots from '../../static/images/footer-dot-yellow.png';
import FeaturesCards from '../components/FeaturesCards';
import generalHelper from '../utils/general';
import CheckMarkerPNG from '../../static/images/checkmark.png';


const EbookHero = styled(GlobalHero)`
  background: #1F2432;
  position: relative;
  padding: 95px 0 40px 0;

  .hero-container {
    display: flex;
    z-index: 1;

    .hero-text {
      max-width: 660px;
      width: 660px;

      h1,
      p {
        color: ${theme.textColor.white};
      }
    }
    .hero-form {
      position: relative;

      .form-wrapper {
        background: ${theme.body.dark};
        border-radius: 10px;
        padding: 20px 24px;
        width: 400px;
        max-width: 400px;
        position: absolute;
        bottom: -70px;
        left: 140px;
        box-shadow: 0px 4px 4px rgb(0 0 0 / 15%);

        .button-wrapper {
          button {
            display:flex;
            align-items: center;
            justify-content: center;
          }
          img.icon {
            margin: 0 10px 0 0;
            width: 16px;
            height: 16px;
          }
        }
        .footer-wrapper {
          text-align: center;
          padding: 24px 0 0 0;

          a {
            color: ${theme.textColor.blueText};
            margin: 0 3px;
          }
        }
      }
    }
  }

  ${MediaBreakDown.md`
    .hero-container {
      flex-direction: column;

      .hero-text {
        max-width: initial;
        width: initial;
      }
      .hero-form {
        display: flex;
        justify-content: center;

        .form-wrapper {
          width: initial;
        }
      }
    }
  `}
  ${MediaBreakDown.xl`
    .hero-container {
      .hero-form {
        .form-wrapper {
          position: initial;
        }
      }
    }
  `}

  .dots-divider {
    background-image: url(${footerTopDots});
    background-repeat: repeat;
    height: 64px;
    clear: both;
    width: 100%;
    position: absolute;
    bottom: 0;
  }
  .circle {
    shape-outside: circle();
    clip-path: circle();
    position: absolute;
    z-index: 0;
    
    &.red {
      width: 300px;
      height: 300px;
      background: #DD4B4B;
      top: -30px;
      left: 50%;
    }
    &.blue {
      width: 60px;
      height: 60px;
      background: #5986FF;
      top: 70px;
      left: calc(50% + 470px);;
    }
  }
`;
EbookHero.displayName = 'EbookHero';

const EbookContainer = styled(Container)`
  padding-top: 120px;
  padding-right: 30px;
  padding-bottom: 80px;
  padding-left: 30px;

  ${SectionTitle} {
    max-width: 820px;
    text-align: center;
  }

  .columns-wrapper {
    display: flex;

    .column-cover {
      margin: 80px 100px 100px 0;
    }
    .column-description {}
  }

  ${MediaBreakDown.lg`
    .columns-wrapper {
      flex-direction: column;

      .column-cover {
        display: flex;
        justify-content: center;
        margin-right: 0;
        margin-left: 0;
      }
    }
  `}
`;
EbookContainer.displayName = 'EbookContainer';

const EbookImage = styled(GatsbyImage)`
  width: 400px;

  ${MediaBreakDown.lg`
    width: 300px;
  `}
`;
EbookImage.displayName = 'EbookImage';

const SectionWrapper = styled.div.attrs({
  className: 'section',
})`
  padding: 80px 0;
  text-align: center;
`;

const FORM_ID = 'ebook-form';
const BUTTON_CONFIG_INITIAL = {
  text: 'Download free ebook',
  background: theme.background.blue,
};

const EbookTemplate = (props) => {
  /* USE STATE -----------------------------------*/
  const [buttonConfig, setButtonConfig] = React.useState(BUTTON_CONFIG_INITIAL);


  /* PROPS ---------------------------------------*/
  const { location } = props;
  const customerioApiUrl = _get(props, 'data.site.siteMetadata.customerio.apiUrl', '');
  const customerioSiteId = _get(props, 'data.site.siteMetadata.customerio.siteId', '');
  const customerioApiKey = _get(props, 'data.site.siteMetadata.customerio.apiKey', '');
  const templateItem = _get(props, 'data.contentfulEbook', {});
  const formId = _get(templateItem, 'formId', '');
  const seoMeta = {
    title: `${_get(templateItem, 'title', '')} | Template | Workast`,
    description: _get(templateItem, 'tagline', '')
  };
  const features = _get(templateItem, 'features', [])
    .map(featureItem => ({
      ...featureItem,
      description: _get(featureItem, 'description.description', ''),
    }));

  
  /* METHODS -------------------------------------*/
  const handleSubmitForm = React.useCallback(
    async (values) => {
      setButtonConfig({
        text: 'Sending...',
        background: '#95b1ff',
      });

      const response = await axios({
        method: 'post',
        url: `${customerioApiUrl}/api/v1/forms/${formId}/submit`,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${btoa(`${customerioSiteId}:${customerioApiKey}`)}`,
        },
        data: {
          data: {
            ...values,
            // This is because the "CustomerIO" config needs an ID.
            id: values.email,
            // This is because the "CustomerIO" config only accepts string values.
            subscribedToEmails: _get(values, 'subscribedToEmails', false).toString(),
          },
        },
      });

      const responseStatus = _get(response, 'status', 400);
      if (responseStatus >= 200 && responseStatus < 300) {
        document.getElementById(FORM_ID).reset();

        // I do this because the query responds too fast:
        await generalHelper.sleep(1000);

        // Set success message:
        setButtonConfig({
          text: (
            <React.Fragment>
              <img
                src={CheckMarkerPNG}
                className="icon"
                alt="Check"
              />
              Check your email
            </React.Fragment>
          ),
          background: '#2cca87',
        });

        await generalHelper.sleep(2000);
      }

      // Set initial props:
      setButtonConfig(BUTTON_CONFIG_INITIAL);
    },
    [formId]
  );

  const handleDownloadButtonClick = React.useCallback(
    () => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth',
      });
    },
    []
  );

  /* RENDER --------------------------------------*/
  return (
    <Layout
      location={location}
      title={_get(templateItem, 'title', '')}
      useDarkBackground
    >
      <SEO
        title={seoMeta.title}
        description={seoMeta.description}
        type="ebook"
        image={getImage(_get(templateItem, 'seoCover', '') || _get(templateItem, 'cover', ''))}
      />
      <EbookHero>
        <Container className="hero-container">
          <div className="hero-text">
            <HeroHeading>
              {_get(templateItem, 'title', '')}
            </HeroHeading>
            <BodyTextBig mt="30px" mb="45px">
              {_get(templateItem, 'tagline', '')}
            </BodyTextBig>
          </div>
          <div className="hero-form">
            <CustomForm
              title="Get your free copy"
              formId={FORM_ID}
              fields={[
                {
                  type: 'text', label: 'Full Name', name: 'name', required: true,
                },
                {
                  type: 'email', label: 'Work email', name: 'email', required: true,
                },
                {
                  type: 'checkbox', label: 'Subscribe to our newsletter', name: 'subscribedToEmails',
                },
              ]}
              className="form-wrapper"
              footerElement={(
                <span>
                  By submitting this form you are accepting our
                  <a href="/terms-of-service/">Terms of Service</a>
                  and
                  <a href="/privacy-policy/">Privacy Policy</a>
                </span>
              )}
              buttonConfig={buttonConfig}
              onSubmit={handleSubmitForm}
            />
          </div>
        </Container>
        <span className="circle red" />
        <span className="circle blue" />
        <span className="dots-divider" />
      </EbookHero>
      <EbookContainer>
        <SectionTitle mb="18px">{_get(templateItem, 'subtitle', '')}</SectionTitle>
        <div className="columns-wrapper">
          <div className="column-cover">
            <EbookImage
              image={getImage(_get(templateItem, 'cover', ''))}
              alt={_get(templateItem, 'cover.description', '')}
              objectFit="cover"
              objectPosition="50% 50%"
            />
          </div>
          <div className="column-description">
            <ContentWrap mt="80px">
              <ContentfulRichText
                richText={_get(templateItem, 'description', '')}
              />
            </ContentWrap>
          </div>
        </div>
      </EbookContainer>
      <GraySection>
        <Container>
          <SectionTitle>
            What you&apos;ll get from this ebook
          </SectionTitle>
        </Container>
        <FeaturesCards
          items={features}
        />
      </GraySection>
      <Container>
        <SectionWrapper>
          <SectionTitle mb="18px">Get your free copy</SectionTitle>
          <BodyText mb="40px">Get this free ebook in your inbox</BodyText>
          <CTAButton
            type="button"
            onClick={handleDownloadButtonClick}
          >
            Download for free
          </CTAButton>
        </SectionWrapper>
      </Container>
    </Layout>
  );
};

EbookTemplate.propTypes = {
  data: PropTypes.shape({
    contentfulEbook: PropTypes.shape({
      description: PropTypes.shape({}),
      formId: PropTypes.string,
      slug: PropTypes.string,
      subtitle: PropTypes.string,
      tagline: PropTypes.string,
      title: PropTypes.string,
      cover: PropTypes.shape({}),
      seoCover: PropTypes.shape({}),
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    key: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
  }).isRequired,
};

export default EbookTemplate;

export const pageQuery = graphql`
  query ebookBySlug($slug: String!) {
    contentfulEbook(slug: { eq: $slug }) {
      description {
        raw
      }
      formId
      slug
      subtitle
      tagline
      title
      cover {
        description
        gatsbyImageData
      }
      seoCover {
        description
        gatsbyImageData
      }
      features {
        title
        description {
          description
        }
        image {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
    site {
      siteMetadata {
        customerio {
          apiUrl
          siteId
          apiKey
        }
      }
    }
  }
`;
