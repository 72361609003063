/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import _get from 'lodash/get';
import { rem } from 'polished';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import theme from '../../styles/theme';
import MediaBreakDown from '../../styles/MediaBreakDown';


const SliderWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 50px auto 0;
  background: #F7F9FF;

  /**
  This is to make the items all same size:
  */
  .slick-initialized {
    .slick-track {
      display: flex;
      align-items: stretch;

      .slick-slide {
        display: flex;
        height: initial;

        & > div {
          display: flex;

          & > div {
            display: flex !important;
            flex: 1;
            flex-direction: column;
          }
        }
      }
    }
  }

  ${MediaBreakDown.lg`
    margin: 30px auto 0;
  `}
`;
const Card = styled.div`
  position: relative;
  z-index: 1;
  margin: 15px 20px;
  width: calc(100% - 40px) !important;
  border-radius: 6px;
  outline: none;
  .text-wrap {
    padding: 27px 23px 37px;
    background: ${theme.background.light};
    border: 1px solid ${theme.borderColor.silver};
    border-top: 0px;
    height: -webkit-fill-available;
  }
  h5 {
    font-size: ${rem(theme.font.smallParagraph.fontSize)};
    line-height: ${rem(theme.font.smallParagraph.lineHeight)};
    font-weight: 400;
    color: ${theme.textColor.darkGrayText};
  }
  p {
    font-size: ${rem(theme.font.smallParagraph.fontSize)};
    line-height: ${rem(theme.font.smallParagraph.lineHeight)};
  }
  ${MediaBreakDown.lg`
    margin: 15px 10px;
    width: calc(100% - 20px) !important;
  `}
  @media(max-width: 370px) {
    height: 550px;
  }
`;
const ImageWrap = styled.div`
  background: ${props => props.bgColor};
  padding: 23px;
  text-align: center;
  .gatsby-image-wrapper {
    width: auto;
    height: 100%;
    margin: auto;
  }
`;

const settings = {
  infinite: true,
  autoPlay: true,
  slidesToShow: 3,
  speed: 500,
  responsive: [
    {
      breakpoint: 920,
      settings: {
        slidesToShow: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '20%',
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '10%',
      }
    }
  ]
};

const getBackgroundColor = (index) => {
  let bgColor;
  const rest = index % 3;

  switch (rest) {
    case 0:
      bgColor = theme.background.yellow;
      break;
    case 1:
      bgColor = theme.background.blue;
      break;
    case 2:
      bgColor = theme.background.red;
      break;
    default:
      break;
  }

  return bgColor;
};

const FeaturesCards = (props) => {
  const { items } = props;

  return (
    <SliderWrap>
      <Slider {...settings}>
        {items.map((item, index) => (
          <Card key={_get(item, 'title', '')}>
            <ImageWrap bgColor={getBackgroundColor(index)}>
              <GatsbyImage
                image={getImage(_get(item, 'image', ''))}
                alt={_get(item, 'image.description', '')}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </ImageWrap>
            <div className="text-wrap">
              <h5>{`${index + 1}. ${_get(item, 'title', '')}`}</h5>
              <p>{_get(item, 'description', '')}</p>
            </div>
          </Card>
        ))}
      </Slider>
    </SliderWrap>
  );
};

FeaturesCards.defaultProps = {
  items: [],
};
FeaturesCards.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({}),
    })
  ),
};

export default FeaturesCards;
