/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/*------------------------------------------------*/
/* LIBRARIES
/*------------------------------------------------*/
import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _noop from 'lodash/noop';
import _uniqueId from 'lodash/uniqueId';
/*------------------------------------------------*/
/* INTERNAL DEPENDENCIES
/*------------------------------------------------*/
import CustomFormWrapper from './index.style';
import theme from '../../styles/theme';


const EMAIL = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


const CustomForm = (props) => {
  const formId = _get(props, 'formId', '');
  const title = _get(props, 'title', '');
  const fields = _get(props, 'fields', []);
  const className = _get(props, 'className', '');
  const footerElement = _get(props, 'footerElement', null);
  const buttonText = _get(props, 'buttonConfig.text', '');
  const buttonBackground = _get(props, 'buttonConfig.background', theme.background.blue);
  const onSubmit = _get(props, 'onSubmit', null);


  const handleButtonClick = React.useCallback(
    (event) => {
      const data = {};
      let hasError = false;

      fields.forEach((fieldItem) => {
        let value = '';
        switch (fieldItem.type) {
          case 'checkbox':
            value = _get(document.getElementById(fieldItem.name), 'checked', false);
            break;
          case 'email': {
            value = _get(document.getElementById(fieldItem.name), 'value', '');
            const isValidEmail = EMAIL.test(value);
            if (!isValidEmail || (fieldItem.required && !value)) {
              hasError = true;
            }
          }
            break;
          default:
            value = _get(document.getElementById(fieldItem.name), 'value', '');
            if (fieldItem.required && !value) {
              hasError = true;
            }
            break;
        }

        data[fieldItem.name] = value;
      });

      if (!hasError) {
        event.preventDefault();
        onSubmit(data);
      }
    },
    []
  );

  return (
    <CustomFormWrapper
      className={className}
    >
      <h2>{title}</h2>
      <form
        id={formId}
      >
        {fields.map((fieldItem) => {
          switch (_get(fieldItem, 'type')) {
            case 'text':
              return (
                <div
                  key={_get(fieldItem, 'name', '')}
                  className="field-item"
                >
                  <input
                    type="text"
                    placeholder={_get(fieldItem, 'label', '')}
                    id={_get(fieldItem, 'name', '')}
                    name={_get(fieldItem, 'name', '')}
                    required={_get(fieldItem, 'required', false)}
                  />
                </div>
              );
            case 'email':
              return (
                <div
                  key={_get(fieldItem, 'name', '')}
                  className="field-item"
                >
                  <input
                    type="email"
                    placeholder={_get(fieldItem, 'label', '')}
                    id={_get(fieldItem, 'name', '')}
                    name={_get(fieldItem, 'name', '')}
                    required={_get(fieldItem, 'required', false)}
                  />
                </div>
              );
            case 'checkbox':
              return (
                <div
                  key={_get(fieldItem, 'name', '')}
                  className="field-item"
                >
                  <input
                    type="checkbox"
                    id={_get(fieldItem, 'name', '')}
                    name={_get(fieldItem, 'name', '')}
                  />
                  <label htmlFor={_get(fieldItem, 'name', '')}>
                    {_get(fieldItem, 'label', '')}
                  </label>
                </div>
              );
            default:
              return null;
          }
        })}
        {buttonText && (
          <div className="button-wrapper">
            <button
              type="submit"
              style={{ background: buttonBackground }}
              onClick={handleButtonClick}
            >
              {buttonText}
            </button>
          </div>
        )}
      </form>
      {footerElement && (
        <div className="footer-wrapper">
          {footerElement}
        </div>
      )}
    </CustomFormWrapper>
  );
};

CustomForm.defaultProps = {
  // Optional props:
  formId: _uniqueId('form-'),
  title: '',
  fields: [],
  className: '',
  footerElement: null,
  buttonConfig: {
    text: '',
    background: theme.background.blue,
  },
  // Optional methods:
  onSubmit: _noop,
};
CustomForm.propTypes = {
  // Optional props:
  formId: PropTypes.string,
  title: PropTypes.string,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      required: PropTypes.bool,
    })
  ),
  className: PropTypes.string,
  footerElement: PropTypes.element,
  buttonConfig: PropTypes.shape({
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    background: PropTypes.string,
  }),
  // Optional methods:
  onSubmit: PropTypes.func,
};

export default CustomForm;
